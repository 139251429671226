import { useEffect, useState } from "react";
import { SortableList } from "../SortableList";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Ballot_Options } from "../../__generated__/graphql";
import { Typography } from 'antd';
import { GET_BALLOT, GET_BALLOT_OPTIONS } from "../voting/queries";
const { Text } = Typography;


const GET_PREFERENCES = gql`
  query preferences($ballot_id: bigint!) {
    voter_preferences(where: {ballot_id: {_eq: $ballot_id}}) {
      id
      preferences
    }
  }`;

const CREATE_PREFERENCES = gql`
  mutation vote($ballot_id: bigint!, $preferences: [bigint!]) {
    insert_voter_preferences_one(object: {ballot_id: $ballot_id, preferences: $preferences}) {
      id
    }
}`;

const UPDATE_PREFERENCES = gql`
  mutation update_preferences($id: bigint!, $preferences: [bigint!]) {
    update_voter_preferences_by_pk(pk_columns: {id: $id}, _set: {preferences: $preferences}) {
      id
    }
}`;

export const RankOrder = () => {
  const { ballot_id } = useParams();

  const { loading: ballot_loading, error: ballot_error, data: { ballot_by_pk } = {} } = useQuery(GET_BALLOT, { variables: { id: ballot_id } });
  const { loading: options_loading, error: options_error, data: { ballot_options } = {} } = useQuery(GET_BALLOT_OPTIONS, { variables: { ballot_id: ballot_id } });
  const { loading: preferences_loading, error: preferences_error, data: { voter_preferences } = { voter_preferences: null } } = useQuery(GET_PREFERENCES, { variables: { ballot_id: ballot_id } });
  const [create_preferences] = useMutation(CREATE_PREFERENCES, { variables: { ballot_id: ballot_id, preferences: [] }, refetchQueries: [{ query: GET_PREFERENCES, variables: { ballot_id: ballot_id } }] });
  const [update_preferences] = useMutation(UPDATE_PREFERENCES); // Should we refetch??, { refetchQueries: [{ query: GET_PREFERENCES, variables: { ballot_id: ballot_id } }] });
  const [items, setItems] = useState<Ballot_Options[]>([]);
  const [voter_preferences_id, setVoterPreferencesId] = useState(null);

  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    if (!initializing) return;
    if (ballot_loading || ballot_error) return;
    if (ballot_by_pk === null) return; // TODO: handle this as permanent error.
    if (
      !options_loading &&
      !options_error &&
      !preferences_loading &&
      !preferences_error &&
      voter_preferences.length > 0
    ) {
      setVoterPreferencesId(voter_preferences[0].id);
      const preferenceIds = voter_preferences[0].preferences;
      const sortedPreferences = preferenceIds.map((id: bigint) => {
        return ballot_options.find((item: Ballot_Options) => item.id === id);
      });
      setItems(sortedPreferences);
      setInitializing(false);
      return;
    } else if (!options_loading && !options_error && !preferences_loading && !preferences_error) {
      create_preferences({ variables: { ballot_id: ballot_id, preferences: ballot_options.map(({ id }: Ballot_Options) => id) } });
      setItems(ballot_options);
    }
  }, [ballot_loading, ballot_error, ballot_by_pk, initializing, options_loading, options_error, preferences_loading, preferences_error, voter_preferences, ballot_id, ballot_options, create_preferences]);

  const handleUpdates = (newItems: Ballot_Options[]) => {
    setItems(newItems);
    update_preferences({ variables: { id: voter_preferences_id, preferences: newItems.map(({ id }: Ballot_Options) => id) } });
  }

  return (
    <div>
      <Text>{!ballot_loading && !ballot_error && ballot_by_pk.name}</Text>
      <br />
      {!initializing &&
        <SortableList
          items={items}
          onChange={handleUpdates}
          renderItem={(item) => (
            <SortableList.Item id={item.id}>
              {item.name}
              <SortableList.DragHandle />
            </SortableList.Item>
          )}
        />}
    </div>
  );
}