import { Typography } from "antd";

const { Text } = Typography;

export const Home = () => {
    return (
        <div>
            <Text strong>Make your <em>whole</em> opinion matter.</Text>
            <br />
            <Text>
                Express your opinions on a variety of topics, and see how your opinions compare to others.
                Share more than just what you think is best. By sharing more about your preferences, you can
                find common ground with others, and see how your opinions compare to others.
            </Text>
        </div>
    )
};