import { useQuery } from "@apollo/client"

import { List, Spin, Typography } from "antd";

import { Link } from 'react-router-dom';
import { GET_BALLOTS } from "./queries";
const { Text } = Typography;



export const ViewBallots = () => {
    const { loading, error, data } = useQuery(GET_BALLOTS);

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                <Text strong>Express your opinions</Text>
            </div>
            {loading && <Spin />}
            {!loading && error && <Text type="danger">Error loading ballots</Text>}
            {
                !loading &&
                <List
                    itemLayout="horizontal"
                    dataSource={data.ballot}
                    renderItem={(ballot: any) => (
                        <List.Item
                            actions={[]}
                        >
                            <Link to={`/opinions/${ballot.id}`}>{ballot.name}</Link>
                        </List.Item>
                    )}
                />
            }
        </div >
    );

}