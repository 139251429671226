import './App.css';
import { AppLayout } from './components/AppLayout';
import { Route, Routes } from 'react-router-dom';
import { RankOrder } from './components/RankOrder/RankOrder';
import { ViewBallots } from './components/voting/ViewBallots';
import { ManageBallots } from './components/voting/ManageBallots';
import { AuthenticationGuard } from './components/auth/authentication-guard';
import { EditBallot } from './components/voting/EditBallot';
import { Home } from './components/Home';


function App() {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/topics" element={<AuthenticationGuard component={ManageBallots} />} />
        <Route path="/topics/:ballot_id" element={<AuthenticationGuard component={EditBallot} />} />
        <Route path="/opinions" element={<AuthenticationGuard component={ViewBallots} />} />
        <Route path="/opinions/:ballot_id" element={<AuthenticationGuard component={RankOrder} />} />
        <Route path="/callback" element={<Home />} />
        <Route path="*" element={<p>Not Found.</p>} />
      </Route>
    </Routes>
  );
}

export default App;