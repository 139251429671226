import React from 'react';

import { useQuery, useMutation, gql } from "@apollo/client"

import { List, Spin, Modal, Button, Typography } from "antd";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';
import { CreateBallotForm } from './CreateBallot';
import { Ballot } from '../../__generated__/graphql';
import { GET_BALLOTS } from './queries';
const { Text } = Typography;


const DELETE_BALLOT = gql`
    mutation deleteBallot($id: bigint!) {
        delete_ballot_by_pk(id: $id) {
            id
        }
    }
`


export const ManageBallots = () => {
    const { loading, error, data, refetch: refetchBallots } = useQuery(GET_BALLOTS);
    const [deleteBallot] = useMutation(DELETE_BALLOT, { refetchQueries: [{ query: GET_BALLOTS }] });
    const [modalVisible, setModalVisible] = React.useState(false);
    const [selectedBallot, setSelectedBallot] = React.useState<Ballot | null>(null);
    const [createModalVisible, setCreateModalVisible] = React.useState(false);

    const handleDelete = async (id: string) => {
        await deleteBallot({ variables: { id } });
        setModalVisible(false);
        setSelectedBallot(null);
    }

    const handleCreateBallot = async () => {
        await refetchBallots();
        setCreateModalVisible(false);
    }

    return (
        <div>
            {loading && <Spin />}
            {error && <Text type="danger">Error loading ballots</Text>}
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                <Text strong>Manage Topics</Text>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setCreateModalVisible(true)}
                />
            </div>
            {data?.ballot && <List
                itemLayout="horizontal"
                dataSource={data.ballot}
                renderItem={(ballot: any) => (
                    <List.Item
                        actions={[
                            <DeleteOutlined
                                onClick={() => {
                                    setSelectedBallot(ballot);
                                    setModalVisible(true);
                                }}
                            />]}
                    >
                        <Link to={`/topics/${ballot.id}`}>{ballot.name}</Link>
                    </List.Item>
                )}
            />}
            <Modal
                title={`Delete ${selectedBallot?.name}`}
                open={modalVisible}
                onOk={() => handleDelete(selectedBallot?.id)}
                onCancel={() => {
                    setModalVisible(false);
                    setSelectedBallot(null);
                }}
            >
                <p>Are you sure you want to delete {selectedBallot?.name}?</p>
            </Modal>
            <Modal
                title="Create Topic"
                open={createModalVisible}
                onCancel={() => setCreateModalVisible(false)}
                footer={null}
            >
                <CreateBallotForm onSubmit={handleCreateBallot} />
            </Modal>
        </div>
    );

}