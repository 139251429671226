import React, { ReactElement, ReactNode } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';

export const ApolloClientProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const { getAccessTokenSilently } = useAuth0();
  const client = React.useMemo(() => {
    const httpLink = createHttpLink({
        uri: 'https://rank-choice-voting.hasura.app/v1/graphql',
      });
      
      
    const authLink = setContext(async (_, { headers }) => { 
      const token = await getAccessTokenSilently()
      
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        }
      }
    })
  
    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
      connectToDevTools: true,
    });
  }, [getAccessTokenSilently])

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}