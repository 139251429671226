import {gql} from '@apollo/client';

export const GET_BALLOTS = gql`
    query ballots {
        ballot {
            id
            name
            description
        }
}`

export const GET_BALLOT = gql`
  query ballot($id: bigint!) {
    ballot_by_pk(id: $id) {
      id
      name
      description
      created_at
    }
}`

export const GET_BALLOT_OPTIONS = gql`
  query ballot_options($ballot_id: bigint!) {
    ballot_options(where: {ballot_id: {_eq: $ballot_id}}) {
      id
      ballot_id
      name
      description
    }
  }
`;
