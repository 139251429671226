import { Outlet, useLocation, useNavigate } from "react-router-dom";

import '../App.css';
import { AppstoreAddOutlined, CheckSquareOutlined, HomeOutlined } from "@ant-design/icons";
import { Layout, Menu, ConfigProvider } from "antd";

const { Header, Content } = Layout;

export const AppLayout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const rootPath = location.pathname.split('/')[1] || '';

    const onClick = (e) => {
        const key = e.key;
        navigate(`/${key}`);
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Layout: {
                        bodyBg: 'white'
                    }
                },
                token: {
                    colorPrimary: '#000ecf',
                    borderRadius: 2,

                    // Alias Token
                    colorBgContainer: '#f8f8fa',
                },
            }}
        >
            <Layout>
                {/* <Header style={{ display: 'flex', alignItems: 'center' }}> */}
                <Header>
                    {/* Logo Here */}
                    <Menu theme="dark" mode="horizontal" selectedKeys={[rootPath]}
                        onClick={onClick}
                        items={[{ key: '', icon: <HomeOutlined /> }, { key: 'opinions', label: 'Opinions', icon: <CheckSquareOutlined /> }, { key: 'topics', label: 'Topics', icon: <AppstoreAddOutlined /> }]}
                    />
                </Header>
                <Content className="app-content">
                    <Outlet />
                </Content>
            </Layout>
        </ConfigProvider>
    );
}