import { useQuery, gql, useMutation } from "@apollo/client"

import { Button, Col, Form, Input, InputRef, List, Row, Spin, Typography } from "antd";
import { useParams } from 'react-router-dom';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Ballot_Options, Ballot_OptionsQuery } from "../../__generated__/graphql";
import { GET_BALLOT, GET_BALLOT_OPTIONS } from "./queries";
import { useRef } from "react";

const { Text } = Typography;

const ADD_BALLOT_OPTION = gql`
  mutation add_ballot_option($ballot_id: bigint!, $name: String!, $description: String) {
    insert_ballot_options_one(object: {ballot_id: $ballot_id, name: $name, description: $description}) {
        id
        ballot_id
        name
        description
      }
  }
`;

const REMOVE_BALLOT_OPTION = gql`
mutation delete_ballot_option($id: bigint!) {
    delete_ballot_options_by_pk(id: $id) {
        id
        ballot_id
        __typename
      }
  }
`;

type OptionsInfo = {
  name: string;
  description?: string;
};


export const EditBallot = () => {
  const focusRef = useRef<InputRef>(null);
  const { ballot_id } = useParams();
  const { loading, error, data } = useQuery(GET_BALLOT, { variables: { id: ballot_id } });
  const { data: ballot_options_data } = useQuery(GET_BALLOT_OPTIONS, { variables: { ballot_id: ballot_id }});
  const [form] = Form.useForm();
  const [addOption] = useMutation(ADD_BALLOT_OPTION, {
    update(
      cache,
      {
        data: { insert_ballot_options_one }
      }
    ) {
      cache.updateQuery({query: GET_BALLOT_OPTIONS, variables: {ballot_id: ballot_id}}, (data) => {
        return {ballot_options: [...data.ballot_options, insert_ballot_options_one]};
      });
    },
  });

  const [deleteOption] = useMutation(REMOVE_BALLOT_OPTION, {
    update(
      cache, {
        data: { delete_ballot_options_by_pk }
      }
    ) {
      const { ballot_options: currentOptions } = cache.readQuery<Ballot_OptionsQuery>({query: GET_BALLOT_OPTIONS, variables: {ballot_id: ballot_id}}) || {ballot_options: []};
      const newOptions = currentOptions.filter((item) => item.id !== delete_ballot_options_by_pk.id);
      cache.writeQuery({
        query: GET_BALLOT_OPTIONS,
        variables: {ballot_id: ballot_id},
        data: {ballot_options: newOptions}
      });
    }
  });

  const onAddOption = async (values: any) => {
    addOption({ 
      variables: { ballot_id: ballot_id, name: values.name, description: values.description }, 
      optimisticResponse: {
        insert_ballot_options_one: {
          id: "temp-id",
          ballot_id: ballot_id,
          name: values.name,
          description: values.description || null,
          __typename: "ballot_options"
        }
    } });
    // Await because otherwise we cannot keep focus: https://github.com/ant-design/ant-design/issues/40387
    await form.resetFields();
    if (focusRef.current) {
      focusRef.current.focus();
    }
  };

  const onDeleteOption = (id: any) => {
    deleteOption({ variables: { id: id }, optimisticResponse: {
      delete_ballot_options_by_pk: {
        id: id,
        ballot_id: ballot_id,
        __typename: "ballot_options"
      }
    
    }});
  }
  const ballot = data?.ballot_by_pk;

  return (
    <div>
      {loading && !error && !ballot && <Spin size="large" />}
      {error && <Text>Error loading ballot info.</Text>}
      {ballot && ballot_options_data &&
        <div>
          <Text>{data.ballot_by_pk.name}</Text>
          <br />
          <Text>{data.ballot_by_pk.description}</Text>
          <List
            itemLayout="horizontal"
            dataSource={ballot_options_data.ballot_options}
            renderItem={(item: Ballot_Options) => (
              <List.Item
                key={item.id}
                actions={[<DeleteOutlined onClick={() => onDeleteOption(item.id)} />]}
              >
                  {item.name}
                  {/* <br />
                  {item.description} */}
              </List.Item>
            )}
          />
          <Form
            form={form}
            name="add_item"
            initialValues={{ name: "" }}
            onFinish={onAddOption}
            autoComplete="off"
            requiredMark={false}
            style={{minWidth: "100%"}}
          >
            <Row gutter={8}>
              <Col flex={1}>
                <Form.Item<OptionsInfo>
                  name="name"
                  rules={[{ required: true, message: 'Name is required' }]}
                >
                  <Input ref={focusRef} style={{width: "100%"}} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item<OptionsInfo>>
                  <Button type="primary" htmlType="submit" icon={<PlusOutlined />}/>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>}
    </div>
  );
}