import React from 'react';
import { Button, Form, Input, Spin } from 'antd';
import { gql, useMutation } from '@apollo/client';

const CREATE_BALLOT = gql`
  mutation create_ballot($name: String!, $description: String) {
    insert_ballot(objects: {name: $name, description: $description}) {
      returning {
        name
        id
        description
      }
    }
  }
`;

type FieldType = {
  name?: string;
  description?: string;
};

type CreateBallotFormProps = {
  onSubmit: () => void;
}

const { TextArea } = Input;

export const CreateBallotForm = ({ onSubmit }: CreateBallotFormProps) => {

  const [createBallot, { loading }] = useMutation(CREATE_BALLOT);
  const onFinish = async (values: any) => {
    await createBallot({ variables: { name: values.name, description: values.description } });
    onSubmit();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <Form
        name="create_ballot"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Description"
          name="description"
        >
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" icon={loading ? <Spin /> : null}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}